import React from 'react';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BallotIcon from '@mui/icons-material/Ballot';
import PeopleIcon from '@mui/icons-material/People';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SpokeIcon from '@mui/icons-material/Spoke';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  SkillsIcon,
  TrophyRoomIcon,
  FunctionsIcon,
  WorkforceIcon,
} from './customIcons';

const MenuItems = {
  adminHome: {
    name: 'Home',
    path: '/landing',
    icon: <HomeRoundedIcon />,
  },
  participantHome: {
    name: 'Home',
    path: '/landing/landing-homepage',
    icon: <HomeRoundedIcon />,
  },
  myEvents: {
    name: 'My Events',
    path: '/landing/landing-homepage/my-events',
    icon: <CalendarMonthIcon />,
  },
  mySkills: {
    name: 'My Skills',
    path: '/competitions/skills-overview',
    icon: <SkillsIcon />,
  },
  myTeams: {
    name: 'My Teams',
    path: '/landing/teams',
    icon: <SupervisedUserCircleRoundedIcon />,
  },
  content: {
    name: 'Content',
    path: '/labs',
    icon: <ArticleRoundedIcon />,
  },
  hallOfFame: {
    name: 'Hall of Fame',
    path: '/landing/hall-of-fame',
    icon: <AccountBalanceRoundedIcon />,
  },
  users: {
    name: 'Users',
    path: '/labs',
    icon: <PeopleIcon />,
  },
  templateLibrary: {
    name: 'Template Library',
    path: '/org/template-library',
    icon: <BallotIcon />,
  },
  tagDirectory: {
    name: 'Tag Directory',
    path: '/user-management/tags',
    icon: <LoyaltyIcon />,
  },
  dashboards: {
    name: 'Dashboards',
    path: '/assessor/dashboards',
    icon: <DashboardRoundedIcon />,
  },
  trophyRoom: {
    name: 'Trophy Room',
    path: '/competitions/trophy-room',
    icon: <TrophyRoomIcon />,
  },
  workforce: {
    name: 'Workforce',
    path: '/user-management/workforce',
    icon: <WorkforceIcon />,
  },
  // children
  labs: {
    name: 'Labs',
    path: '/labs',
  },
  vms: {
    name: 'VMs',
    path: '/labs/vms',
  },
  quizzes: {
    name: 'Quizzes',
    path: '/labs/quizzes',
  },
  challenges: {
    name: 'Challenges',
    path: '/tasks',
  },
  affiliated: {
    name: 'Affiliated',
    path: '/user-management/affiliated-users',
    icon: <LinkIcon />,
  },
  nonAffiliated: {
    name: 'Non-Affiliated',
    path: '/user-management/non-affiliated-users',
    icon: <LinkOffIcon />,
  },
  squads: {
    name: 'Squad(s)',
    path: '/user-management/squads',
    icon: <SpokeIcon />,
  },
  functions: {
    name: 'Functions',
    path: '/user-management/workforce/functions',
    icon: <FunctionsIcon />,
  },
};

export default MenuItems;
