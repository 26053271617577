import { withStyles } from 'tss-react/mui';
import { InputBase } from '@mui/material';

const StyledSelect = withStyles(InputBase, (theme) => ({
  root: {
    '& svg': {
      fill:
        theme?.palette?.type === 'dark'
          ? 'rgba(235, 242, 255, 1)'
          : theme?.fdProColors?.electricBlue[700],
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 12,
    fontWeight: 500,
    color: theme?.palette?.sidebar?.modeSelector?.color,
    padding: '4px 36px 4px 12px',
    backgroundColor: theme?.palette?.sidebar?.modeSelector?.background,
  },
}));

export default StyledSelect;
