import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box, Select, MenuItem, InputLabel, useTheme } from '@mui/material';
import * as singleSpa from 'single-spa';
import ScoreIcon from '@mui/icons-material/Score';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CampaignIcon from '@mui/icons-material/Campaign';
import ChatIcon from '@mui/icons-material/Chat';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  SidebarProvider,
  SidebarInset,
  SidebarGroup,
  SidebarMenu,
  SidebarSeparator,
} from '@/components/ui/sidebar';
import {
  globalStore,
  useSnapshot,
  FdTypography,
  useLocalStorage,
  Authorization,
  setTheme,
  FdTooltip,
  useInitializeGlobalStore,
  useRecentLinks,
  useAuthSession,
} from '@fifthdomain/fe-shared';
import { useLocation } from 'react-router-dom';
import {
  getSystemTime,
  getUserAssessment,
  listDashboardsByOrgId,
  listUrls,
} from '../graphql/queries';
import NavFooter from '../components/NavFooter';
import { getCompetitionStatus } from '../shared/utils/getCompetitionStatus';
import MenuItems from '../components/MenuItems';
import StyledSelect from '../components/StyledSelect';
import { getSelectedNavItems } from '../shared/utils/getSelectedNavItems';
import { APP_URL_PATTERNS, SIDEBAR_COOKIE_NAME } from '../constants';
import Logo from '../components/Logo';
import { getCookieByName } from '../shared/utils/cookie';
import ChildAppRoutes from './ChildAppRoutes';
import useSyncMuiThemeWithTailwind from '../hooks/useSyncMuiThemeWithTailwind';
import MainMenu from '../components/MainMenu';
import RecentLinks from '../components/RecentLinks';

const AppSidebar = () => {
  const [userAssessId, setUserAssessId] = useState(undefined);
  const [competitionStatus, setCompetitionStatus] = useState(undefined);
  const [guidedCompetition, setGuidedCompetition] = useState(false);
  const [roleMode, setRoleMode] = useState('Manage');
  const [sidebarOpenState, setSidebarOpenState] = useState(null);
  const pathName = useLocation().pathname;
  const {
    userId,
    userType,
    orgId,
    orgPricingTier,
    isAffiliated,
    permissions,
    theme,
    orgName,
    userName,
    userEmail,
    userAvatarKey,
  } = useSnapshot(globalStore);
  const { recentLinks, deleteRecentLink } = useRecentLinks({ userId });
  const appTheme = useTheme();

  // sync Mui theme with tailwind
  useSyncMuiThemeWithTailwind();

  const { user } = useAuthSession();

  // initialize globalStore from host app
  useInitializeGlobalStore(user?.username);

  const [userPreferencesSidebar, setUserPreferencesSidebar] = useLocalStorage(
    'user-preferences-sidebar-user-mode',
    {
      defaultValue: {
        [userId]: {
          mode: 'Manage',
        },
      },
    },
  );

  useEffect(() => {
    setRoleMode(userPreferencesSidebar?.[userId]?.mode ?? 'Manage');
    setSidebarOpenState(getCookieByName(SIDEBAR_COOKIE_NAME) === 'true');
  }, [userId, userPreferencesSidebar]);

  const baseMenu = {
    navigationItems: [],
    handleOnSelectedItem: (__, path) => {
      singleSpa.navigateToUrl(path);
    },
    ...getSelectedNavItems(pathName),
  };

  const hasCustomOrgPricingTier =
    orgPricingTier !== undefined &&
    !['STARTER', 'ADVANCED'].includes(orgPricingTier);

  const hasManagerialPermission = isAffiliated
    ? Authorization.hasManagerialPermissions(permissions)
    : false;

  const isParticipant = userType === 'PARTICIPANT';
  const isManageMode = roleMode === 'Manage';
  const recentLinksRoleMode =
    hasManagerialPermission && isManageMode ? 'MANAGE' : 'PARTICIPATE';
  const roleFilteredRecentLinks =
    recentLinks?.filter((rc) => rc?.role === recentLinksRoleMode) || [];
  const [menuItems, setMenuItems] = useState(baseMenu);

  // user with create permission
  const canManageContent = Authorization.canManageContent(permissions);
  // user with review content
  const canReviewContent = Authorization.canReviewContent(permissions);
  const canViewDashboards = Authorization.canViewDashboards(
    permissions,
    orgPricingTier,
  );
  // user with manage-tags permission
  const canManageTags = Authorization.canManageTags(
    permissions,
    orgPricingTier,
  );
  const canManageWorkforce = Authorization.canManageWorkforce(permissions);
  const canManageSquads = Authorization.canManageSquads(permissions);
  const canViewTemplateLibrary =
    Authorization.canViewTemplateLibrary(permissions);
  const canManageUsers = Authorization.canManageUsers(permissions);
  const isFdAdmin = Authorization.isFdAdmin(permissions);

  const { data: serverTime, refetch: refetchServerTime } = useQuery(
    gql(getSystemTime),
    { skip: !user?.username },
  );

  const { data: dashboardsData } = useQuery(gql(listDashboardsByOrgId), {
    variables: {
      orgId,
    },
    skip: !orgId,
  });

  const { refetch } = useQuery(gql(getUserAssessment), {
    variables: {
      id: userAssessId,
    },
    skip: !userAssessId,
    onCompleted: (_data) => {
      const {
        assessment: { endDateTime, startDateTime, guided },
      } = _data?.getUserAssessment || { assessment: {} };
      const _status = getCompetitionStatus(
        startDateTime,
        endDateTime,
        serverTime?.getSystemTime,
      );
      setCompetitionStatus(_status);
      setGuidedCompetition(guided);
    },
  });

  const { data: urlData } = useQuery(gql(listUrls), {
    variables: {
      filter: {
        orgId: { eq: orgId },
        theme: { eq: theme?.toUpperCase() },
      },
    },
    skip: !orgId,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (pathName.includes('homepage') || pathName.includes('labs/courses')) {
      refetchServerTime();
      refetch({ id: userAssessId });
    }
  }, [pathName, refetch, refetchServerTime, userAssessId]);

  // Build Competitions SideBar for Participants
  const sideNavCompParticipant = (_competitionStatus, _guidedCompetition) => {
    const navItems = [];
    if (isParticipant) {
      const userAssessmentId = pathName.split('/').pop();
      setUserAssessId(userAssessmentId);
      if (_competitionStatus !== 'ENDED') {
        navItems.push({
          name: 'Challenges',
          path: `/competitions/competition-tasks/${userAssessmentId}`,
          icon: <EmojiEventsIcon />,
          dataCy: 'sidebar-competitions-tasks',
        });
        navItems.push({
          name: 'Overview',
          path: `/competitions/competition-overview/${userAssessmentId}`,
          icon: <DashboardIcon />,
          dataCy: 'sidebar-competitions-overview',
        });
        navItems.push({
          name: 'Announcements',
          path: `/competitions/competition-announcements/${userAssessmentId}`,
          icon: <CampaignIcon />,
          dataCy: 'sidebar-competitions-announcements',
        });
        if (!_guidedCompetition) {
          navItems.push({
            name: 'Chat Forum',
            path: `/competitions/competition-chat-forum/${userAssessmentId}`,
            icon: <ChatIcon />,
            dataCy: 'sidebar-competitions-chat-forum',
          });
        }
      }
      if (!_guidedCompetition) {
        navItems.push({
          name: 'Scoreboard',
          path: `/competitions/competition-scoreboard/${userAssessmentId}`,
          icon: <ScoreIcon />,
          dataCy: 'sidebar-competitions-overview',
        });
      }
    }
    return navItems;
  };

  const sideNavTrainingParticipant = (_competitionStatus) => {
    const navItems = [];
    if (isParticipant) {
      const userAssessmentId = pathName.split('/').pop();
      setUserAssessId(userAssessmentId);
      if (_competitionStatus !== 'ENDED') {
        navItems.push({
          name: 'Challenges',
          path: `/competitions/training-tasks/${userAssessmentId}`,
          icon: <EmojiEventsIcon />,
          dataCy: 'sidebar-training-tasks',
        });
        navItems.push({
          name: 'Overview',
          path: `/competitions/training-overview/${userAssessmentId}`,
          icon: <DashboardIcon />,
          dataCy: 'sidebar-training-overview',
        });
      }
    }
    return navItems;
  };

  const sideNavFdTrainingParticipant = (_competitionStatus) => {
    const navItems = [];
    if (isParticipant) {
      const userAssessmentId = pathName.split('/').pop();
      setUserAssessId(userAssessmentId);
      if (_competitionStatus !== 'ENDED') {
        navItems.push({
          name: 'Challenges',
          path: `/assessor/fd-training/tasks/${userAssessmentId}`,
          icon: <EmojiEventsIcon />,
        });
        navItems.push({
          name: 'Overview',
          path: `/assessor/fd-training/overview/${userAssessmentId}`,
          icon: <DashboardIcon />,
        });
      }
    }
    return navItems;
  };

  // Build Assessor SideBar for Participants
  const sideNavAssessParticipant = () => {
    const navItems = [];
    if (isParticipant) {
      const userAssessmentId = pathName.split('/').pop();
      navItems.push({
        name: 'Challenges',
        path: `/assessor/assessment-tasks/${userAssessmentId}`,
        icon: <AssignmentIcon />,
        dataCy: 'sidebar-assessment-tasks',
      });
      navItems.push({
        name: 'Overview',
        path: `/assessor/assessment-overview/${userAssessmentId}`,
        icon: <DashboardIcon />,
        dataCy: 'sidebar-assessment-overview',
      });
    }
    return navItems;
  };

  const MenuHeader = () => (
    <Box className="my-1 group-data-[collapsible=icon]:opacity-0">
      <SidebarSeparator />
      <Box className="flex flex-col my-2 pl-6 w-full">
        {isManageMode && (
          <Box className="mb-2">
            <FdTooltip title={orgName}>
              <span>
                <FdTypography
                  variant="body1"
                  style={{
                    width: '197px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  color="secondary"
                >
                  {orgName}
                </FdTypography>
              </span>
            </FdTooltip>
          </Box>
        )}
        <Box className="flex items-center gap-x-3">
          <InputLabel htmlFor="mode-select-label">
            <FdTypography variant="captiontext1" color="primary">
              Mode
            </FdTypography>
          </InputLabel>
          <Select
            labelId="mode-select-label"
            id="mode-select"
            value={roleMode}
            label=""
            onChange={(e) => {
              setRoleMode(e?.target?.value);
              setUserPreferencesSidebar({
                ...userPreferencesSidebar,
                [userId]: {
                  mode: e?.target?.value,
                },
              });
              if (e?.target?.value === 'Manage') {
                globalStore.userType = 'ADMIN';
                setTheme('light');
                singleSpa.navigateToUrl(MenuItems.adminHome.path);
              } else {
                globalStore.userType = 'PARTICIPANT';

                setTheme(theme);
                singleSpa.navigateToUrl(MenuItems.participantHome.path);
              }
            }}
            input={<StyledSelect />}
          >
            <MenuItem value="Participate">Participate</MenuItem>
            <MenuItem value="Manage">Manage</MenuItem>
          </Select>
        </Box>
      </Box>
      <SidebarSeparator />
    </Box>
  );

  const hallOfFame = {
    ...MenuItems.hallOfFame,
    caption: orgName, // show current org name
  };

  // Build SideBar for User
  const sideNav = () => {
    const navItems = [];

    // Non Affiliated user
    if (!isAffiliated) {
      navItems.push(MenuItems.participantHome);
      navItems.push(MenuItems.myEvents);
      navItems.push(MenuItems.mySkills);
      navItems.push(MenuItems.myTeams);
      navItems.push(MenuItems.trophyRoom);
      return navItems;
    }

    // Affiliated user
    if (isAffiliated) {
      // no managerial permissions
      if (!hasManagerialPermission) {
        navItems.push(MenuItems.participantHome);
        navItems.push(MenuItems.myEvents);
        navItems.push(MenuItems.mySkills);
        navItems.push(MenuItems.myTeams);
        navItems.push(MenuItems.trophyRoom);
        navItems.push(hallOfFame);
        return navItems;
      }

      // manager or admin permissions
      if (hasManagerialPermission) {
        // participate mode is selected
        if (!isManageMode) {
          navItems.push(MenuItems.participantHome);
          navItems.push(MenuItems.myEvents);
          navItems.push(MenuItems.mySkills);
          navItems.push(MenuItems.myTeams);
          navItems.push(MenuItems.trophyRoom);
          navItems.push(hallOfFame);
          return navItems;
        }

        // manage mode is selected
        if (isManageMode) {
          navItems.push(MenuItems.adminHome);
          // manage content
          if (hasCustomOrgPricingTier) {
            const contentChildren = canManageContent
              ? [MenuItems.labs, MenuItems.vms, MenuItems.challenges]
              : // user with review content
              canReviewContent
              ? [MenuItems.challenges]
              : [];
            // user with manage content or Fd admin
            if (canManageContent || isFdAdmin) {
              contentChildren.push(MenuItems.lessons);
            }
            navItems.push({
              ...MenuItems.content,
              children: contentChildren,
            });
          }
          // manage users
          if (canManageUsers || canManageSquads) {
            navItems.push({
              ...MenuItems.users,
              children: [
                ...(canManageUsers
                  ? [MenuItems.affiliated, MenuItems.nonAffiliated]
                  : []),
              ],
            });
          }
          // workforce
          if (canManageWorkforce && hasCustomOrgPricingTier) {
            navItems.push({
              ...MenuItems.workforce,
              children: [MenuItems.functions],
            });
          }
          // template library
          if (canViewTemplateLibrary) {
            navItems.push(MenuItems.templateLibrary);
          }
          // manage tags
          if (canManageTags) {
            navItems.push(MenuItems.tagDirectory);
          }
          // hall of fame
          navItems.push(hallOfFame);
          // dashboards
          if (
            dashboardsData?.listDashboardsByOrgId?.items?.length > 0 &&
            canViewDashboards
          ) {
            navItems.push(MenuItems.dashboards);
          }
          // for FdAdmin
          if (isFdAdmin) {
            navItems.push(MenuItems.organisations);
            navItems.push(MenuItems.contentLibrary);
            navItems.push(MenuItems.contentCatalogue);
          }
          return navItems;
        }
      }
    }
    return navItems;
  };

  const getNavItems = (url, _competitionStatus, _guidedCompetition) => {
    let navItems = [];
    const matchPattern = (patterns) =>
      patterns.some((pattern) => pattern.test(url));
    switch (true) {
      case matchPattern(APP_URL_PATTERNS.ADMIN):
        navItems = sideNav();
        break;
      case matchPattern(APP_URL_PATTERNS.PARTICIPANT):
        navItems = sideNavCompParticipant(
          _competitionStatus,
          _guidedCompetition,
        );
        break;
      case matchPattern(APP_URL_PATTERNS.TRAINING_PARTICIPANT):
        navItems = sideNavTrainingParticipant(_competitionStatus);
        break;
      case matchPattern(APP_URL_PATTERNS.FD_TRAINING_PARTICIPANT):
        navItems = sideNavFdTrainingParticipant(_competitionStatus);
        break;
      case matchPattern(APP_URL_PATTERNS.ASSESS_PARTICIPANT):
        navItems = sideNavAssessParticipant();
        break;
      default:
        navItems = [];
    }
    return navItems;
  };

  useEffect(() => {
    const newNavItems = getNavItems(
      pathName,
      competitionStatus,
      guidedCompetition,
    );
    const newMenu = { ...menuItems };
    newMenu.navigationItems = newNavItems;
    setMenuItems({ ...newMenu, ...getSelectedNavItems(pathName) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathName,
    competitionStatus,
    roleMode,
    userType,
    hasCustomOrgPricingTier,
  ]);

  if (menuItems?.navigationItems.length === 0) {
    return null; // hide sidebar if no entries
  }

  const key = urlData?.listUrls?.items?.[0]?.key;

  const isWhiteLabelledOrgs = key !== undefined;
  const userInfo = {
    name: userName,
    email: userEmail,
    userAvatarKey,
  };
  const role = userType === 'PARTICIPANT' ? 'PARTICIPATE' : 'MANAGE';
  const selectedMenuItem = menuItems?.selected;

  return (
    <SidebarProvider defaultOpen={sidebarOpenState}>
      {user?.username && (
        <Sidebar collapsible="icon">
          <SidebarHeader>
            <Box height={180} className="flex items-center justify-center">
              {isWhiteLabelledOrgs ? (
                <Logo logo={key} isWhiteLabelled />
              ) : (
                <Logo isWhiteLabelled={false} />
              )}
            </Box>
          </SidebarHeader>
          <SidebarContent>
            {hasManagerialPermission && <MenuHeader />}
            <SidebarGroup>
              <SidebarMenu>
                <MainMenu
                  items={menuItems?.navigationItems}
                  selectedMenuItem={selectedMenuItem}
                />
              </SidebarMenu>
            </SidebarGroup>
            <RecentLinks
              recentLinks={roleFilteredRecentLinks}
              deleteRecentLink={deleteRecentLink}
              role={role}
            />
          </SidebarContent>
          <SidebarFooter>
            <NavFooter user={userInfo} />
          </SidebarFooter>
          <SidebarRail />
        </Sidebar>
      )}
      <SidebarInset
        style={{ background: appTheme?.palette?.background?.default }}
      >
        <ChildAppRoutes />
      </SidebarInset>
    </SidebarProvider>
  );
};

export default AppSidebar;
