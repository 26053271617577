import React from 'react';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { GoalIcon, SquareLibraryIcon, Building2Icon } from 'lucide-react';
import { FdMenuIcon } from '@fifthdomain/fe-shared';
import { TrophyRoomIcon, WorkforceIcon } from './customIcons';

const MenuItems = {
  adminHome: {
    name: 'Home',
    path: '/landing',
    icon: <HomeRoundedIcon />,
  },
  participantHome: {
    name: 'Home',
    path: '/landing/landing-homepage',
    icon: <HomeRoundedIcon />,
  },
  myEvents: {
    name: 'My Events',
    path: '/landing/landing-homepage/my-events',
    icon: <FdMenuIcon type="EVENT" />,
  },
  mySkills: {
    name: 'My Skills',
    path: '/competitions/skills-overview',
    icon: <FdMenuIcon type="SKILL" />,
  },
  myTeams: {
    name: 'My Teams',
    path: '/landing/teams',
    icon: <FdMenuIcon type="TEAM" />,
  },
  content: {
    name: 'Content',
    path: '/labs',
    icon: <SquareLibraryIcon />,
  },
  hallOfFame: {
    name: 'Hall of Fame',
    path: '/landing/hall-of-fame',
    icon: <AccountBalanceRoundedIcon />,
  },
  users: {
    name: 'Users',
    path: '/labs',
    icon: <PeopleIcon />,
  },
  templateLibrary: {
    name: 'Template Library',
    path: '/org/template-library',
    icon: <FdMenuIcon type="TEMPLATE" />,
  },
  tagDirectory: {
    name: 'Tag Directory',
    path: '/user-management/tags',
    icon: <FdMenuIcon type="TAG" />,
  },
  dashboards: {
    name: 'Dashboards',
    path: '/assessor/dashboards',
    icon: <DashboardRoundedIcon />,
  },
  trophyRoom: {
    name: 'Trophy Room',
    path: '/competitions/trophy-room',
    icon: <TrophyRoomIcon />,
  },
  workforce: {
    name: 'Workforce',
    path: '/user-management/workforce',
    icon: <WorkforceIcon />,
  },
  organisations: {
    name: 'Organisations',
    path: '/org',
    icon: <Building2Icon />,
  },
  contentLibrary: {
    name: 'Challenges Store',
    path: '/org/content-library',
    icon: <GoalIcon />,
  },
  contentCatalogue: {
    name: 'Lease Catalogue',
    path: '/org/content-lease-catalogue',
    icon: <FdMenuIcon type="LEASE_CATALOGUE" />,
  },
  // children
  labs: {
    name: 'Labs',
    path: '/labs',
    icon: <FdMenuIcon type="LAB" />,
  },
  vms: {
    name: 'VMs',
    path: '/labs/vms',
    icon: <FdMenuIcon type="VM" />,
  },
  challenges: {
    name: 'Challenges',
    path: '/tasks',
    icon: <FdMenuIcon type="CHALLENGE" />,
  },
  lessons: {
    name: 'Lessons',
    path: '/tasks/lessons',
    icon: <FdMenuIcon type="LESSON" />,
  },
  affiliated: {
    name: 'Affiliated',
    path: '/user-management/affiliated-users',
    icon: <FdMenuIcon type="AFFILIATED" />,
  },
  nonAffiliated: {
    name: 'Non-Affiliated',
    path: '/user-management/non-affiliated-users',
    icon: <FdMenuIcon type="NON_AFFILIATED" />,
  },
  squads: {
    name: 'Squad(s)',
    path: '/user-management/squads',
    icon: <FdMenuIcon type="SQUAD" />,
  },
  functions: {
    name: 'Functions',
    path: '/user-management/workforce/functions',
    icon: <FdMenuIcon type="FUNCTION" />,
  },
};

export default MenuItems;
