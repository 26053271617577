import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import { signOut } from 'aws-amplify/auth';
import { Box } from '@mui/material';
import {
  EllipsisVertical,
  LogOut,
  Copy,
  UserPenIcon,
  Building2Icon,
  UsersIcon,
  ChevronDownIcon,
} from 'lucide-react';
import { useIntercom } from 'react-use-intercom';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
  useSidebar,
} from '@/components/ui/sidebar';
import {
  getAvatarName,
  getAvatarColor,
  useClipboard,
  globalStore,
  useSnapshot,
  orgDetailsPermissions,
  Authorization,
  PERMISSIONS_AFFILIATED,
  FooterLogo,
  successToastMessage,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { getOrg } from '../graphql/queries';
import { getPreSignedUrl } from '../shared/utils/getImageFromS3';
import { SupportChatIcon } from './customIcons';

const UserAvatar = ({ avatarUrl, userName }) => (
  <Avatar className="h-8 w-8 rounded-lg">
    <AvatarImage src={avatarUrl} alt={userName} />
    <AvatarFallback
      className="rounded-lg text-white"
      style={{ backgroundColor: getAvatarColor(userName) }}
    >
      {getAvatarName(userName)}
    </AvatarFallback>
  </Avatar>
);

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  userName: PropTypes.string,
};

UserAvatar.defaultProps = {
  avatarUrl: null,
  userName: '',
};

const NavFooter = ({ user, openIntercom, setOpenIntercom }) => {
  const { isMobile } = useSidebar();
  const { pathname } = useLocation();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const { orgId, userType, permissions, userEmail, userHmacId } =
    useSnapshot(globalStore);
  const [copyToClipboard] = useClipboard();
  const isParticipant = userType === 'PARTICIPANT';
  const hideMyTeams = pathname.includes('/landing/teams');
  const userName = user?.name;
  const {
    update: updateIntercom,
    hide: hideIntercom,
    show: showIntercom,
  } = useIntercom();

  useEffect(() => {
    updateIntercom({
      email: userEmail,
      userHash: userHmacId,
    });
  }, [updateIntercom, userEmail, userHmacId]);

  const { data: orgData } = useQuery(gql(getOrg), {
    variables: {
      id: orgId,
    },
    skip: !orgId,
  });

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      if (user?.userAvatarKey) {
        try {
          const fileUrlS3 = await getPreSignedUrl(user.userAvatarKey);
          setAvatarUrl(fileUrlS3);
        } catch (error) {
          setAvatarUrl(null);
        }
      } else {
        setAvatarUrl(null);
      }
    };

    fetchAvatarUrl();
  }, [user?.userAvatarKey]);

  const menuItems = [
    {
      key: 'manageProfile',
      label: 'Manage Profile',
      icon: <UserPenIcon className="size-4" />,
      onClick: () => singleSpa.navigateToUrl('/landing/user/profile'),
    },
    {
      key: 'orgDetails',
      label: 'Organisation Details',
      icon: <Building2Icon className="size-4" />,
      onClick: () => singleSpa.navigateToUrl('/landing/org/profile'),
      show: Authorization.hasPermission(permissions, orgDetailsPermissions),
    },
    {
      key: 'copyAlias',
      label: orgData?.getOrg?.alias,
      icon: <Copy className="size-4" />,
      onClick: () => {
        copyToClipboard(orgData?.getOrg?.alias);
        successToastMessage('Copied to clipboard');
      },
      show: Authorization.hasPermission(permissions, [
        PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
      ]),
    },
  ];

  return (
    <>
      <SidebarMenu>
        {isParticipant && !hideMyTeams && (
          <SidebarMenuItem>
            <SidebarMenuButton
              tooltip="My Teams"
              onClick={() => singleSpa.navigateToUrl('/landing/teams')}
            >
              <UsersIcon />
              <span className="font-semibold">My Teams</span>
            </SidebarMenuButton>
          </SidebarMenuItem>
        )}
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                tooltip={userName}
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground hover:text-sidebar-foreground"
              >
                <UserAvatar avatarUrl={avatarUrl} userName={userName} />
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{userName}</span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
                <EllipsisVertical className="ml-auto size-4" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              side={isMobile ? 'bottom' : 'right'}
              align="end"
              sideOffset={4}
            >
              <DropdownMenuLabel className="p-0 font-normal">
                <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                  <UserAvatar avatarUrl={avatarUrl} userName={userName} />
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">{userName}</span>
                    <span className="truncate text-xs">{user.email}</span>
                  </div>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                {menuItems.map(
                  ({ key, label, icon, onClick, show = true }) =>
                    show && (
                      <DropdownMenuItem key={key} onClick={onClick}>
                        {icon}
                        {label}
                      </DropdownMenuItem>
                    ),
                )}
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={async () => {
                    await signOut();
                    window.location.href = window.location.origin;
                  }}
                >
                  <LogOut className="size-4" />
                  Sign Out
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
      <SidebarSeparator />
      <Box className="flex items-center justify-between group-data-[collapsible=icon]:justify-center mx-2 group-data-[collapsible=icon]:mx-0">
        <FdTooltip title="FifthDomain Help and Support" placement="right">
          <Box
            role="button"
            id="fd-intercom-launcher"
            onClick={() => {
              if (openIntercom) {
                hideIntercom();
              } else {
                showIntercom();
              }
              setOpenIntercom(!openIntercom);
            }}
            className="flex items-center justify-center size-9 rounded-full hover:scale-125 transition-transform duration-200"
            sx={{ backgroundColor: '#3034fd', color: '#fff' }}
          >
            {openIntercom ? <ChevronDownIcon /> : <SupportChatIcon />}
          </Box>
        </FdTooltip>
        <FooterLogo />
      </Box>
    </>
  );
};

NavFooter.defaultProps = {
  user: {
    name: '',
    email: '',
    userAvatarKey: undefined,
  },
};

NavFooter.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    userAvatarKey: PropTypes.string,
  }),
  openIntercom: PropTypes.bool.isRequired,
  setOpenIntercom: PropTypes.func.isRequired,
};

export default NavFooter;
