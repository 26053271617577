import React from 'react';
import PropTypes from 'prop-types';
import * as singleSpa from 'single-spa';
import { Box } from '@mui/material';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuAction,
} from '@/components/ui/sidebar';
import { Trash2Icon } from 'lucide-react';
import { FdMenuIcon } from '@fifthdomain/fe-shared';

const RecentLinks = ({ recentLinks, deleteRecentLink, role }) => {
  return (
    recentLinks.length > 0 && (
      <SidebarGroup>
        <SidebarGroupLabel>Recent</SidebarGroupLabel>
        <SidebarMenu className="group-data-[collapsible=icon]:opacity-0">
          {recentLinks?.map((entry) => (
            <SidebarMenuItem className="peer">
              <SidebarMenuButton
                size="default"
                onClick={() => {
                  if (entry?.newTab) {
                    window.open(entry.url, '_blank', 'noopener,noreferrer');
                  } else {
                    singleSpa.navigateToUrl(entry.url);
                  }
                }}
              >
                <Box className="flex gap-x-2 justify-start items-center truncate">
                  <Box
                    className="flex items-center justify-center px-1 rounded h-6"
                    sx={(fdTheme) => ({
                      backgroundColor:
                        fdTheme?.fdProColors?.event?.[
                          `${entry.type?.toLowerCase()}s`
                        ],
                      color: '#000',
                    })}
                  >
                    <FdMenuIcon type={entry.type} className="w-4 h-4" />
                  </Box>
                  <Box className="relative truncate flex overflow-x-hidden">
                    <span
                      className={`text-sm whitespace-nowrap ${
                        entry?.name?.length > 23
                          ? 'truncate hover:animate-marquee hover:delay-1000 hover:overflow-visible'
                          : ''
                      }`}
                    >
                      {entry.name}
                    </span>
                  </Box>
                </Box>
              </SidebarMenuButton>
              <SidebarMenuAction
                showOnHover
                onClick={() => {
                  deleteRecentLink({
                    id: entry?.id,
                    role,
                  });
                }}
              >
                <Trash2Icon />
              </SidebarMenuAction>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroup>
    )
  );
};

RecentLinks.propTypes = {
  recentLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteRecentLink: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default RecentLinks;
