export const USER_PERMISSION_TYPE = Object.freeze({
  MANAGE_GROUPS: 'MANAGE_GROUPS',
  MANAGE_TRAINING: 'MANAGE_TRAINING',
  CREATE: 'CREATE',
  VIEW_INSIGHTS: 'VIEW_INSIGHTS',
  FD_ADMIN: 'FD_ADMIN',
  MANAGE_TAGS: 'MANAGE_TAGS',
  MANAGE_PARTICIPANTS: 'MANAGE_PARTICIPANTS',
  MANAGE_MANAGERS: 'MANAGE_MANAGERS',
});

export const APP_URL_PATTERNS = {
  ADMIN: [
    /\/assessor\/?$/,
    /\/assessor\/users\/?$/,
    /\/assessor\/create\/?$/,
    /\/assessor\/view\//,
    /\/assessor\/fd-training\/view\//,
    /\/assessor\/assessment-start\//,
    /\/assessor\/dashboards(?:\/|$)/,
    /\/assessor\/duplicate\//,
    /\/assessor\/assessment-complete\//,
    /\/assessor\/fd-training\/complete\//,
    /\/assessor\/assessment-post-reflection\//,
    /\/assessor\/survey\/?$/,
    /\/user-management\/?$/,
    /\/user-management\/affiliated-users\/?$/,
    /\/user-management\/squads\/?$/,
    /\/user-management\/squads\/create\/?$/,
    /\/user-management\/squads\/view\//,
    /\/user-management\/non-affiliated-users\/?$/,
    /\/user-management\/tags\/?$/,
    /\/user-management\/groups\/?$/,
    /\/user-management\/groups\/create?$/,
    /\/user-management\/groups\/edit\//,
    /\/user-management\/quizzes\/?$/,
    /\/user-management\/quizzes\/view\//,
    /\/user-management\/quizzes\/create?$/,
    /\/tasks\/view\//,
    /\/tasks\/edit\//,
    /\/tasks\/edit-draft\//,
    /\/tasks\/review\//,
    /\/tasks\/create\/?$/,
    /\/tasks\/lessons\/?$/,
    /\/tasks\/lessons\/edit\//,
    /\/tasks\/lessons\/create\/?$/,
    /\/tasks\/?$/,
    /\/competitions\/competitions-homepage\/?$/,
    /\/landing\/teams\/?$/,
    /\/competitions\/skills-overview\/?$/,
    /\/competitions\/view-team\//,
    /\/competitions\/competition-start\//,
    /\/competitions\/training-start\//,
    /\/assessor\/fd-training\/start\//,
    /\/competitions\/view\//,
    /\/competitions\/affiliated-view-user\//,
    /\/competitions\/non-affiliated-view-user\//,
    /\/competitions\/duplicate\//,
    /\/competitions\/create\/?$/,
    /\/competitions\/?$/,
    /\/competitions\/users\/?$/,
    /\/competitions\/training\/create\//,
    /\/competitions\/training\/edit\//,
    /\/competitions\/trophy-room\/?$/,
    /\/competitions\/trophy-room\//,
    /\/competitions\/competition-complete\//,
    /\/competitions\/training-complete\//,
    /\/labs\/vms\/?$/,
    /\/labs\/courses\/?$/,
    /\/labs\/courses\/create\/?$/,
    /\/labs\/courses\/view\//,
    /\/labs\/courses\/view-admin\//,
    /\/labs\/courses\/duplicate\//,
    /\/labs\/quizzes\/?$/,
    /\/labs\/quizzes\/create\/?$/,
    /\/labs\/quizzes\/view\//,
    /\/labs\/?$/, // for labs
    /\/labs\/view\//,
    /\/labs\/test-lab\//,
    /\/labs\/view-course-lab\//,
    /\/labs\/editBuild\//,
    /\/labs\/editDraft\//,
    /\/labs\/create\/?$/,
    /\/landing\/?$/,
    /\/landing\/org\/profile\/?$/,
    /\/landing\/landing-homepage\/?$/,
    /\/landing\/landing-homepage\/my-events\/?$/,
    /\/landing\/hall-of-fame\/?$/,
    /\/org\/template-library\/?$/,
    /\/org\/template-library\/create\/training-template\/?$/,
    /\/org\/template-library\/view\/training-template\//,
    /\/org\/template-library\/create\/competition\/?$/,
    /\/org\/template-library\/create\/assessment\/?$/,
    /\/org\/template-library\/view\/assessment\//,
    /\/org\/template-library\/view\/competition\//,
    /\/assessor\/create\/template\/?$/,
    /\/landing\/user\/profile\/?$/,
    /\/user-management\/workforce\/functions\/?$/,
    /\/user-management\/workforce\/functions\/create?$/,
    /\/user-management\/workforce\/functions\/edit\//,
    /\/org\/?$/,
    /\/org\/create\/?$/,
    /\/org\/view\//,
    /\/org\/content-library\/?$/,
    /\/org\/content-lease-catalogue\/?$/,
  ],
  PARTICIPANT: [
    /\/competitions\/competition-tasks\//,
    /\/competitions\/competition-scoreboard\//,
    /\/competitions\/competition-announcements\//,
    /\/competitions\/competition-chat-forum\//,
    /\/competitions\/competition-overview\//,
  ],
  TRAINING_PARTICIPANT: [
    /\/competitions\/training-tasks\//,
    /\/competitions\/training-overview\//,
  ],
  FD_TRAINING_PARTICIPANT: [
    /\/assessor\/fd-training\/tasks\//,
    /\/assessor\/fd-training\/overview\//,
  ],
  ASSESS_PARTICIPANT: [
    /\/assessor\/assessment-overview\//,
    /\/assessor\/assessment-tasks\//,
  ],
};

export const SIDEBAR_COOKIE_NAME = 'fd-sidebar:state';

export const ENTRY_TYPE = {
  ASSESSMENT: 'ASSESSMENT',
  COMPETITION: 'COMPETITION',
  COURSE: 'COURSE',
  FUNCTION: 'FUNCTION',
  TRAINING: 'TRAINING',
  QUIZ: 'QUIZ',
  LAB: 'LAB',
  CHALLENGE: 'CHALLENGE',
  USER: 'USER',
  USER_PROFILE: 'USER_PROFILE',
  TROPHY: 'TROPHY',
  HALL_OF_FAME: 'HALL_OF_FAME',
  SKILL: 'SKILL',
  EVENT: 'EVENT',
  TEAM: 'TEAM',
  ORGANISATION: 'ORGANISATION',
  TAG: 'TAG',
  TEMPLATE: 'TEMPLATE',
  VM: 'VM',
  LESSON: 'LESSON',
};
