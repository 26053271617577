import React from 'react';
import * as singleSpa from 'single-spa';
import { ChevronRight } from 'lucide-react';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import {
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';

const MainMenu = ({ items, selectedMenuItem }) => {
  return items?.map((item) =>
    item?.children?.length > 0 ? (
      <Collapsible key={item.name} asChild className="group/collapsible">
        <SidebarMenuItem>
          <CollapsibleTrigger asChild>
            <SidebarMenuButton
              tooltip={item.name}
              isActive={
                selectedMenuItem === item.name ||
                item?.children?.some(
                  (subItem) => selectedMenuItem === subItem.name,
                )
              }
              size="md"
            >
              {item.icon}
              <span className="font-semibold">{item.name}</span>
              <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
            </SidebarMenuButton>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <SidebarMenuSub>
              {item.children?.map((subItem) => (
                <SidebarMenuSubItem key={subItem.name}>
                  <SidebarMenuSubButton
                    asChild
                    size="md"
                    isActive={selectedMenuItem === subItem.name}
                    onClick={() => {
                      singleSpa.navigateToUrl(subItem.path);
                    }}
                  >
                    <button type="button" role="menuitem" className="w-full">
                      {subItem?.icon}
                      <span>{subItem.name}</span>
                    </button>
                  </SidebarMenuSubButton>
                </SidebarMenuSubItem>
              ))}
            </SidebarMenuSub>
          </CollapsibleContent>
        </SidebarMenuItem>
      </Collapsible>
    ) : (
      <SidebarMenuItem>
        <SidebarMenuButton
          tooltip={item?.name}
          isActive={selectedMenuItem === item?.name}
          size="md"
          onClick={() => {
            singleSpa.navigateToUrl(item?.path);
          }}
        >
          {item?.icon}
          <span className="font-semibold">{item?.name}</span>
        </SidebarMenuButton>
      </SidebarMenuItem>
    ),
  );
};

export default MainMenu;
