import React, { useEffect } from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { useAppTheme, setShowNav } from '@fifthdomain/fe-shared';
import { AuthProvider } from '@fifthdomain/auth';
import Sidebar from './pages/Sidebar';
import GraphQlClient from './shared/GraphQlClient';
import './sidebar.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'sidebar',
  seed: 'sidebar',
});

const App = () => {
  const theme = useAppTheme();

  useEffect(() => {
    setShowNav(true);
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GraphQlClient>
          <BrowserRouter>
            <StylesProvider generateClassName={generateClassName}>
              <AuthProvider>
                <Sidebar />
              </AuthProvider>
            </StylesProvider>
          </BrowserRouter>
        </GraphQlClient>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
