import React from 'react';
import PropTypes from 'prop-types';
import { IntercomProvider } from 'react-use-intercom';

const IntercomChatProvider = ({ children, ...props }) => {
  return (
    <IntercomProvider
      appId="mxnen818"
      autoBootProps={{
        hideDefaultLauncher: true,
      }}
      apiBase="https://api-iam.au.intercom.io"
      autoBoot
      {...props}
    >
      {children}
    </IntercomProvider>
  );
};

IntercomChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntercomChatProvider;
