import React from 'react';
import PropTypes from 'prop-types';
import {
  globalStore,
  useSnapshot,
  FdLogo,
  S3Image,
} from '@fifthdomain/fe-shared';

function Logo({ logo, isWhiteLabelled, isIcon }) {
  const globalSnap = useSnapshot(globalStore);
  // save logo s3 image url to global store
  const setGlobalStoreLogoImageUrl = (url) => {
    globalStore.logoS3ImageUrl = url;
  };

  if (logo && isWhiteLabelled)
    return (
      <S3Image
        image={logo}
        size={{ height: '205px' }}
        s3ImageUrl={globalSnap.logoS3ImageUrl}
        onImageUrlFromS3={setGlobalStoreLogoImageUrl}
      />
    );
  return <FdLogo isIcon={isIcon} />;
}

Logo.propTypes = {
  logo: PropTypes.string,
  isWhiteLabelled: PropTypes.bool.isRequired,
  isIcon: PropTypes.bool,
};

Logo.defaultProps = {
  logo: '',
  isIcon: false,
};

export default Logo;
